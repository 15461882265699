<template>
  <div>
    <zg-hero
      :id="contentId"
      product="electricity"
      :page-type="pageType ?? 'product-page'"
      :title="title"
      :text="text"
      :tabs="tabs"
      :background="background"
      :breadcrumbs="breadcrumbs"
    >
      <slot
        v-for="slot in Object.keys($slots)"
        :slot="slot"
        :name="slot"
      />
    </zg-hero>
  </div>
</template>

<script>
  import Vue from 'vue'
  import { ZgHero } from '@zc/components/ZgHero'
  import { mapGetters } from 'vuex'

  export default {
    name: 'Hero',
    components: {
      ZgHero
    },
    props: {
      contentId: {
        type: String,
        default: null
      },
      breadcrumbs: {
        type: Array,
        default: null
      },
      background: {
        type: Object,
        default: null
      },
      tabs: {
        type: Array,
        default: null
      },
      text: {
        type: String,
        default: null
      },
      title: {
        type: String,
        default: null
      }
    },
    computed: {
      ...mapGetters('variations', ['getVariations']),
      pageType () {
        return this.getVariations?.['el-e-s-0035-insurance-on-elskling']
          ?.enabled &&
          this.getVariations?.['el-e-s-0035-insurance-on-elskling']
            ?.variationKey === 'tabs'
          ? 'home-page'
          : 'product-page'
      }
    },
    async mounted () {
      await Vue.prototype.EXPERIMENTS.activate('el-e-s-0035-insurance-on-elskling', {}, Vue.prototype.ZGA)
    }
  }
</script>
